.mode-toggle-button {
    position: center;
    z-index: 1000;
  }
  
  .sun {
    background:transparent;
    font-size: 25px;
    border: none; /* Remove default border */
    border-radius: 50%; /* Makes the button round */
    width: 50px; /* Set the width of the button */
    height: 50px; /* Set the height of the button to match width */
    display: flex; /* Flexbox to center the icon */
    justify-content: center; /* Center icon horizontally */
    align-items: center; /* Center icon vertically */
    cursor: pointer; /* Change the cursor to pointer on hover */
    outline: none; /* Remove the default outline */
    transition: background 0.3s ease; /* Smooth background transition */
    color: #8EB19C;
  }
  
  .sun:hover {
    color: rgb(255, 255, 255);
  }
  
  .sun:active {
    color: rgb(49, 128, 212);
  }
  
  .moon {
    background:transparent;
    font-size: 25px;
    border: none; /* Remove default border */
    border-radius: 50%; /* Makes the button round */
    width: 50px; /* Set the width of the button */
    height: 50px; /* Set the height of the button to match width */
    display: flex; /* Flexbox to center the icon */
    justify-content: center; /* Center icon horizontally */
    align-items: center; /* Center icon vertically */
    cursor: pointer; /* Change the cursor to pointer on hover */
    outline: none; /* Remove the default outline */
    transition: background 0.3s ease; /* Smooth background transition */
    color: rgb(162, 204, 210); /* Icon color */
  }
  
  .moon:hover {
    color: rgb(255, 255, 255);
  }
  
  .moon:active {
    color: #31d473
  }

  /* Responsive padding for mobile */
@media (max-width: 768px) {
    body {
      padding-top: 80px; /* Increase padding for smaller screens if header is larger */
    }
  }