   /*====== #region Dark Mode Styles ======*/
.screen-card {
   background-color: #000000;
   border: 16px solid #00110a;
   border-radius: 50px;
   position: relative;
   box-shadow: 0 0 20px rgba(0, 255, 136, 0.308);
   overflow: hidden;
   padding: 20px; /* Adjust padding to create space inside the card */
   background: repeating-linear-gradient(
     0deg, 
     rgba(255, 255, 255, 0.025) 0px, 
     rgba(255, 255, 255, 0.025) 2px, 
     rgba(0, 0, 0, 0) 2px, 
     rgba(0, 0, 0, 0) 4px
  );
   width: auto; /* Let the width adjust based on the content */
   height: auto; /* Let the height adjust based on the content */
   max-width: 90%; /* Optional: restrict the width to avoid overflowing on larger screens */
   margin: 0 auto; /* Center the card */
}

 
 /* Glow inside the screen */
 .screen-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: radial-gradient(circle, rgba(0, 255, 136, 0.15), rgba(0, 0, 0, 0.8)); */
    border-radius: 35px; /* Match the border radius of the main screen */
    z-index: 1;
    filter: blur(2px);
    box-shadow: -4px 2px 2px rgba(145, 255, 191, .5);
 }

/* Rotated ellipse reflection */
.reflection-ellipse {
   position: absolute;
   top: -250px; /* Move it slightly above the card */
   right: 0px; /* Offset to the right */
   width: 225px; /* Size of the ellipse */
   height: 600px; /* Size of the ellipse */
   background-color: rgba(145, 255, 191, 0.046); /* Semi-transparent white */
   clip-path: ellipse(50% 50%); /* Ellipse shape */
   transform: rotate(-60deg); /* Rotate the ellipse to align with the corner */
   z-index: 5; /* Ensure it's on top of the screen content */
   pointer-events: none; /* Let clicks pass through */
  
   
}

/* Moving Scan Line */
.scan-line {
   position: absolute;
   bottom: 0; /* Start the scan line at the bottom */
   left: 0;
   width: 100%;
   height: 4px;
   background-color: rgba(255, 255, 255, 0.02); /* Light-colored scan line */
   animation: moveScan 8s ease-in-out infinite; /* 8s duration for slow upward movement */
   z-index: 3;
}

@keyframes moveScan {
   0% {
      bottom: 0; /* Start at the bottom */
   }
   100% {
      bottom: 100%; /* Move to the top */
   }
}

 
 /* Content inside the screen */
 .screen-content {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
    z-index: 2; /* Ensure content is on top of the glow effect */
    color: rgba(206, 255, 232, 0.725);
    font-family: 'Consolata', monospace; /* Futuristic or techy font */
    padding: 20px;
    text-align: center;
 }
 
 .screen-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000; /* Retro-style base color similar to wood paneling */
    padding: 20px;
    border-radius: 60px; /* Simulate the casing like the TV stand */
    box-shadow: 0 0px 35px rgba(203, 255, 210, 0.354);
    z-index: 1;
 }

 .screen-backlight{
   color: #ffffff;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(49, 212, 114, 0.796), rgba(49, 212, 115, 0.05));
    border-radius: 50px; /* Match the border radius of the main screen */
    z-index: 2;
 }
   /*====== End of Dark Mode Styles ======*/
   /*====== Section: Light Mode Styles ======*/

 .screen-card-light {
   background-color: #ffffff;
   border: 16px solid #7c969b;
   border-radius: 50px;
   position: relative;
   box-shadow: 0 0 20px rgba(0, 98, 255, 0.308);
   overflow: hidden;
   padding: 20px; /* Adjust padding to create space inside the card */
   background: repeating-linear-gradient(
     0deg, 
     rgba(255, 255, 255, 0.025) 0px, 
     rgba(255, 255, 255, 0.025) 2px, 
     rgba(0, 0, 0, 0) 2px, 
     rgba(0, 0, 0, 0) 4px
  );
   width: auto; /* Let the width adjust based on the content */
   height: auto; /* Let the height adjust based on the content */
   max-width: 90%; /* Optional: restrict the width to avoid overflowing on larger screens */
   margin: 0 auto; /* Center the card */
}

 
 /* Glow inside the screen */
 .screen-card-light::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: radial-gradient(circle, rgba(0, 255, 136, 0.15), rgba(0, 0, 0, 0.8)); */
    border-radius: 35px; /* Match the border radius of the main screen */
    z-index: 1;
    filter: blur(2px);
    box-shadow: -6px 6px 2px rgb(255, 255, 255);
 }

/* Rotated ellipse reflection */
.reflection-ellipse-light {
   position: absolute;
   top: -250px; /* Move it slightly above the card */
   right: 0px; /* Offset to the right */
   width: 225px; /* Size of the ellipse */
   height: 600px; /* Size of the ellipse */
   background-color: rgba(145, 255, 191, 0.046); /* Semi-transparent white */
   clip-path: ellipse(50% 50%); /* Ellipse shape */
   transform: rotate(-60deg); /* Rotate the ellipse to align with the corner */
   z-index: 5; /* Ensure it's on top of the screen content */
   pointer-events: none; /* Let clicks pass through */
  
   
}

/* Moving Scan Line */
.scan-line-light {
   position: absolute;
   bottom: 0; /* Start the scan line at the bottom */
   left: 0;
   width: 100%;
   height: 4px;
   background-color: rgba(255, 255, 255, 0.02); /* Light-colored scan line */
   animation: moveScan 8s ease-in-out infinite; /* 8s duration for slow upward movement */
   z-index: 3;
}
 
 /* Content inside the screen */
 .screen-content-light {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
    z-index: 2; /* Ensure content is on top of the glow effect */
    color: rgba(206, 255, 232, 0.725);
    font-family: 'Consolata', monospace; /* Futuristic or techy font */
    padding: 20px;
    text-align: center;
 }
 
 .screen-container-light {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #bbbb92; /* Retro-style base color similar to wood paneling */
    padding: 20px;
    border-radius: 60px; /* Simulate the casing like the TV stand */
    box-shadow: 0 0px 35px rgba(0, 0, 0, 0.73);
    z-index: 1;
 }

.screen-backlight-light{
   color: #ffffff;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(49, 128, 212, 0.796), rgb(10, 46, 85));
    border-radius: 50px; /* Match the border radius of the main screen */
    z-index: 2;
 }
 /*====== End of Light Mode Styles ======*/