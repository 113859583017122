.background{
    width: 100vw; /* Full width of the viewport */
  height: 100vh; /* Full height of the viewport */
  margin: 0; /* Remove any margins */
  padding: 0; /* Remove any padding */
  display: flex; /* Flexbox for centering content if needed */
  flex-direction: column; /* Optional: stack content vertically */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  transition: background-color 0.5s ease; /* Smooth transition between colors */
    background-color: #000000;
    transition: background-color 0.5s ease; /* Smooth transition between colors */
  }
  
  .background-light {
    width: 100vw; /* Full width of the viewport */
  height: 100vh; /* Full height of the viewport */
  margin: 0; /* Remove any margins */
  padding: 0; /* Remove any padding */
  display: flex; /* Flexbox for centering content if needed */
  flex-direction: column; /* Optional: stack content vertically */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  transition: background-color 0.5s ease; /* Smooth transition between colors */
  background-color: rgb(106, 140, 172);
    transition: background-color 0.5s ease; /* Smooth transition between colors */
  }

  