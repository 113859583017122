.work-in-progress {
  font-family: 'Inconsolata', monospace; /* Terminal-style font */
  text-align: center;
  padding: 20px;
  color: rgba(201, 255, 230, 0.625); /* Matching green text */
}


.terminal-text {
  font-family: 'Incosolata', monospace; /* Terminal-style font */
  color: rgba(206, 255, 232, 0.725); /* Matching green text */
}

.right-align-text {
  text-align: right;
  color: rgba(206, 255, 232, 0.725);
}