/* General Header Styles */
.header {
  width: 100%;
  display: flex;
  justify-content: space-between; /* Pushes logo to the left and nav to the right */
  align-items: center;
  padding: 10px;
  background-color: #00110a;
  box-shadow: 0px -20px 40px rgba(203, 255, 210, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
/* Logo on the left */
.header__logo a {
text-decoration: none;
color: #8EB19C;
font-size: 36px;
font-family: 'Inconsolata', monospace;
font-weight: bold;
margin: 0;
padding-left: 20px;
}

.header__logo a:hover {
  color: #31d473;
}

/* Navigation on the right */
.header__nav {
display: flex;
}

/* Navigation on the right - Desktop */
.header__nav ul {
  list-style: none;
  display: flex; /* Horizontal layout on desktop */
  gap: 20px;
  margin: 0;
  padding-right: 20px;
}

.header__nav a {
  text-decoration: none;
  color: #8EB19C;
  font-size: 24px;
  font-family: 'Inconsolata', monospace;
  font-weight: bold;
}

.header__nav a:hover {
  color: #31d473;
}

/* Hamburger Menu - initially hidden */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  padding-right: 20px;
}

.hamburger div {
  width: 25px;
  height: 3px;
  background-color:#8EB19C;
  margin: 5px;
  transition: all 0.3s ease;
}

/* Media Query for Mobile (max-width: 768px) */
@media (max-width: 768px) {
  .header__logo a {
    text-decoration: none;
    color: #8EB19C;
    font-size: 24px;
    font-family: 'Inconsolata', monospace;
    font-weight: bold;
    margin: 0;
    padding-left: 20px;
    }

  .header__nav {
    display: none; /* Hide navigation initially */
    flex-direction: column;
    position: absolute;
    top: 50px; /* Position under the header */
    right: 5%;
    background-color: rgba(0, 0, 0, 0.95);
    width: 100%;
    text-align:center;
    padding: 20px;
    border-radius: 35px;
  }

  .header__nav.open {
    display: flex; /* Show navigation when the hamburger is clicked */
  }

  /* Ensure vertical layout for the nav links */
  .header__nav ul {
    display: block; /* Switch to block layout for vertical alignment */
    width: 100%; /* Ensure the list spans the full width */
    text-align:center;
  }

  .header__nav li {
    padding: 15px 0px; /* Add spacing between each link */
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Optional: Add subtle dividers */
  }

  .header__nav a {
    font-size: 20px; /* Slightly smaller font for mobile */
    padding: 0px 0px;
    display: block; /* Ensure each link takes full width */
    width: 100%;
  }

  .hamburger {
    display: flex; /* Show hamburger on smaller screens */
  }

}

/* General Header Styles - Light Mode */
.header-light {
  width: 100%;
  display: flex;
  justify-content: space-between; /* Pushes logo to the left and nav to the right */
  align-items: center;
  padding: 10px;
  background-color: #bbbb92; /* Light background */
  box-shadow: 0px -20px 40px rgba(0, 0, 0, 1); /* Lighter shadow */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

/* Logo on the left - Light Mode */
.header__logo-light a {
  text-decoration: none;
  color: #575740; /* Darker green for logo text */
  font-size: 36px;
  font-family: 'Inconsolata', monospace;
  font-weight: bold;
  margin: 0;
  padding-left: 20px;
  -webkit-text-stroke: 1px #393924; /* Black stroke with 2px width */
  text-shadow: none; /* Optional: remove any text shadow */
}

.header__logo-light a:hover {
  color: rgb(49, 128, 212);
  -webkit-text-stroke: 1px rgb(23, 74, 127); /* Black stroke with 2px width */
}

/* Navigation on the right - Light Mode */
.header__nav-light {
  display: flex;
}

/* Navigation on the right - Desktop - Light Mode */
.header__nav-light ul {
  list-style: none;
  display: flex; /* Horizontal layout on desktop */
  gap: 20px;
  margin: 0;
  padding-right: 20px;
}

.header__nav-light a {
  text-decoration: none;
  color: #00695c; /* Darker green for nav links */
  font-size: 24px;
  font-family: 'Inconsolata', monospace;
  font-weight: bold;
}

.header__nav-light a:hover {
  color: #00bfa5; /* Lighter hover color */
}

/* Hamburger Menu - Light Mode */
.hamburger-light {
  display: none;
  flex-direction: column;
  cursor: pointer;
  padding-right: 20px;
}

.hamburger-light div {
  width: 25px;
  height: 3px;
  background-color: #00695c; /* Darker green for hamburger menu */
  margin: 5px;
  transition: all 0.3s ease;
}

/* Media Query for Mobile (max-width: 768px) - Light Mode */
@media (max-width: 768px) {
  
  .header__logo-light a {
    text-decoration: none;
    font-size: 24px;
    font-family: 'Inconsolata', monospace;
    font-weight: bold;
    margin: 0;
    padding-left: 20px;
    color: #575740; /* Darker green for logo text */
    -webkit-text-stroke: 1px #393924; /* Black stroke with 2px width */
    text-shadow: none; /* Optional: remove any text shadow */
  }

  .header__nav-light {
    display: none; /* Hide navigation initially */
    flex-direction: column;
    position: absolute;
    top: 50px; /* Position under the header */
    right: 5%;
    background-color: rgba(255, 255, 255, 0.95); /* Light background */
    width: 100%;
    text-align: center;
    padding: 20px;
    border-radius: 35px;
  }

  .header__nav-light.open {
    display: flex; /* Show navigation when the hamburger is clicked */
  }

  /* Ensure vertical layout for the nav links */
  .header__nav-light ul {
    display: block; /* Switch to block layout for vertical alignment */
    width: 100%; /* Ensure the list spans the full width */
    text-align: center;
  }

  .header__nav-light li {
    padding: 15px 0px; /* Add spacing between each link */
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* Optional: Add subtle dividers */
  }

  .header__nav-light a {
    font-size: 20px; /* Slightly smaller font for mobile */
    padding: 0px 0px;
    display: block; /* Ensure each link takes full width */
    width: 100%;
  }

  .hamburger-light {
    display: flex; /* Show hamburger on smaller screens */
  }
}